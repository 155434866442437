*{
    padding: 0%;
    margin: 0;
}

.faq-main-sec-2{
    /* background-image: url('./assets/new-coin-bg.jpg'); */
    background-image: url(../Data/assets/new-coin-bg.jpg);
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: cover;
    max-width: 500px;
    height: 800px;
    position: relative;
    width: 100%;
    margin: 0 auto;
    border-radius: 25px;
}

.faq-winner-data {
    display: flex;
    text-align: justify;
    justify-content: center;
    width: 90%;
}
.faq-parag-1 {
    position: relative;
    left: 24px;
    color: white;
    top: 10rem;
    background-color: rgba(0,0,0,.5);
    color: #fff;
    padding: 15px;
    border-radius: 20px;
    overflow: auto;
    width: auto;
    height: 500px;
}
.faq-back-5 {
 
    display: flex;
    text-align: left;
    justify-content: left;
}
.faq-back-5 img {
    position: relative;
    top: 7rem;
    left: 2rem;
}
.faq-logo-1 {
    display: flex;
    text-align: center;
    justify-content: center;
}
.faq-logo-1 img {
    position: absolute;
    width: 25%;
    top: 3rem;
}
@media only screen and (max-width: 480px){
    
    .faq-back-5 img {
        width: 75%;
        top: 6rem;
     }
    }

@media only screen and (max-width: 375px){

.faq-back-5 img {
    
    left: 1rem;
    }    
}