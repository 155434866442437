.motion-main-div{
    overflow-x: hidden;
    overflow-y: hidden;
}

.coins-animation{
    background-image: url(../Data/coin.png);
    background-repeat: no-repeat;
    position: absolute;
    height:100px;
    width:100px;
}

.margin-left-one{
    margin-left: 100px;
}

.margin-left-two{
    margin-left: 140px;
}

.margin-left-three{
    margin-left: 160px;
}

.margin-left-four{
    margin-left: 190px;
}