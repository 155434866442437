*{
    padding: 0%;
    margin: 0;
}
.redeem-main-sec-2{
    /* background-image: url('./assets/new-coin-bg.jpg'); */
    background-image: url(../Data/assets/new-coin-bg.jpg);
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: cover;
    max-width: 500px;
    height: 800px;
    position: relative;
    width: 100%;
    margin: 0 auto;
    border-radius: 25px;
}
.redeem-back-5 {
 
    display: flex;
    text-align: left;
    justify-content: left;
}
.redeem-winner-btn button {
    border: 3px solid #ffe269;
    padding: 3px 12px;
    border-radius: 10px;
    background-color: #986522;
    color: white;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1px;
    cursor: pointer;
}
.redeem-back-5 img {
    position: relative;
    top: 7rem;
    left: 2rem;
}
.redeem-logo-1 {
    display: flex;
    text-align: center;
    justify-content: center;
}
.redeem-logo-1 img {
    position: absolute;
    width: 25%;
    top: 3rem;
}
.redeem-start-img {
    display: flex;
    text-align: center;
    justify-content: center;
}
.redeem-start-img img {
    position: relative;
    top: 15rem;
}
.redeem-table-1 {
    display: flex;
    text-align: center;
    justify-content: center;
}
.redeem-table-1 table {
    position: relative;
    top: 12rem;
    left: 0;
    background-color: rgba(0,0,0,.5);
    color: #fff;
    border-radius: 15px;
}
table, tr, td{
    border:none;
    color: white;
   }
   .redeem-table-1 td {
    padding: 0px 45px 0px 10px;
}
.redeem-table-1 button {
    padding-left: 25px;
}

.redeem-table-1 td {
    font-size: 25px;
    font-weight: 600;
    text-align: center;

}
@media only screen and (max-width: 525px){
    .redeem-table-1 td {
        padding: 0px 30px 0px 0px;
    }
}
@media only screen and (max-width: 480px){
    
    .redeem-back-5 img {
        width: 75%;
        top: 6rem;
     }
     .redeem-start-img img {
        width: 75%;
    }
    /* .table-1 table {
        top: 12rem;
        left: 0;
    } */
}
@media only screen and (max-width: 375px){
    
    .redeem-back-5 img {
        left: 1rem;
        }
        .redeem-table-1 td {
        padding: 0px 15px 0px 0px;
    }
}    