.background-black{
    background-color: black;
}

.btn-bg-transparent{
    background: transparent;
    border-style: none;
}

.play-btn-bg-transparent{
    height:70px;
}