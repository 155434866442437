*{
    padding: 0%;
    margin: 0;
}
.play-main-sec-2{
    /* background-image: url('./assets/new-coin-bg.jpg'); */
    background-image: url(../Data/assets/new-coin-bg.jpg);
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: cover;
    max-width: 500px;
    height: 800px;
    position: relative;
    width: 100%;
    margin: 0 auto;
    border-radius: 25px;
}
.play-audio-1{
    display: none;
}
.play-btns-4 {
    display: flex;
    justify-content: space-between;
    position: relative;
    top: 10px;
    padding: 0 25px;
}

div#id-1 {
    top: 5px;
}

.play-ques-bg {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
}
.play-menu-1 {
    padding-top: 20rem;
    gap: 4rem;
}
#id-1 {
    position: relative;
    top: 5rem;
}

.play-ques-bg-1{
    /* width: 200px; */
    margin-top: 30px;
    height:45px;
    align-items: center;
    padding: 14.5px 20px 14.5px 30px;
    border: 2px solid #ccc;
    border-radius: 27.5px;
    font-family: 'Nunito', sans-serif;
    color: white; 

    position: relative;
    left: 35px;
    width: 85%;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    /* background-color: rgb(106, 123, 10); */
    background: linear-gradient(to left, #e9b778 0%,#ff5722 60%,#c9a110 110%);
    /* background: linear-gradient(to left, #f35f3b 0%,#e13812 60%,#f79081 110%); */
}

.play-ques-bg-1 a {
    text-decoration: none;
}
.play-ques-bg img {
    width: 80%;
}
.play-ques-bg-1 h5 {
    position: relative;
    top: 40px;
    color: white;
}

.play-tm-pg-1 p {
    position: relative;
    top: 1rem;
    left: 3rem;
    margin: 0 100px 0 0;
    background-color: rgba(0,0,0,.4);
    padding: 5px;
    border-radius: 12px;
    color: white;
    font-size: 22px;
    font-weight: 600;
}

.play-tm-pg-1 {
    display: flex;
    text-align: center;
    justify-content: space-between;
    margin-top: 2rem;
}
/* .nums-1 {
    display: flex;
    justify-content: space-between;
    margin: 80px 50px 0px 50px;
    color: black;
    font-size: 24px;
    font-weight: 900;
} */
.play-choose-1 {
    position: relative;
    top: 20px;
    left: 35px;
    width: 85%;
    color: white;
    font-size: 16px;
    font-weight: 600;
    text-align: center;

}
.play-choose-1 p {
    background-color: rgb(0 0 0 / 75%);
    color: #fff;
    padding: 35px 35px 35px 35px;
    border-radius: 15px;
    font-size: 20px;
    font-weight: 400;
}

@media only screen and (max-width: 480px){
.play-ques-bg img {
    width: 80%;
}
.play-ques-bg-1 h5 {
   top: 34px;
}
.play-time-1 p {
    right: 2.5rem;
}
.play-choose-1 p{
    font-size: 18px;
}
.play-tm-pg-1{
    font-size: 18px;
}

}
@media only screen and (max-width: 375px){
    
    .play-ques-bg-1 h5 {
        top: 32px;
     }
     .play-time-1 p {
        right: 3rem;
    }
    .play-choose-1 {
        left: 30px;
        width: 82%;
    }
  }

  .not-select{
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .loading-div {
    background-color: #272229;
    width: 100%;
    height: 100vh;
    z-index: 1000;
    position: fixed;
  }