*{
    padding: 0%;
    margin: 0;
}

.category-main-sec-5{
    /* background-image: url('./assets/new-coin-bg.jpg'); */
    background-image: url(../Data/assets/new-coin-bg.jpg);
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: cover;
    max-width: 500px;
    height: 800px;
    position: relative;
    width: 100%;
    margin: 0 auto;
    border-radius: 25px;
}

.category-logo-1 {
    display: flex;
    text-align: center;
    justify-content: center;
}
.category-logo-1 img {
    position: absolute;
    width: 65%;
    top: 3rem;
}
.category-back-5 {
 
    display: flex;
    text-align: left;
    justify-content: left;
}
.category-back-5 img {
    position: relative;
    top: 2rem;
    left: 1rem;
}

.category-ques-bg {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    gap: 3rem;
}
.category-ques-bg.category-menu-1 img {
    position: relative;
    top: 28rem;
    width: 75%;
}
@media only screen and (max-width: 480px){
    .category-back-5 img {
        width: 80%;
        top: 2rem;
    }
    .category-ques-bg.category-menu-1 img {
        top: 27rem;
    }
    .category-logo-1 img {
        width: 75%;
    }
    
    } 
    @media only screen and (max-width: 375px){
    
        .category-back-5 img {
            left: 0rem;
         }
         .category-logo-1 img {
            width: 85%;
        }
        .category-ques-bg.category-menu-1 img {
            top: 26rem;
            width: 84%;
        }
    }