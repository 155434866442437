* {
    margin: 0%;
    padding: 0%;
    box-sizing: border-box;
}

.home-main-sec {
    /* background-image: url('./assets/new-coin-bg.jpg'); */
    background-image: url(../Data/assets/new-coin-bg.jpg);
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: cover;
    max-width: 500px;
    height: 800px;
    position: relative;
    width: 100%;
    margin: 0 auto;
    border-radius: 25px;
}

.home-btn-1 img{
    width: 115px;
    position: relative;
    top: 22em;
    animation: Play 0.7s linear infinite;

} 
.home-btn-1 {
    display: flex;
    text-align: center;
    justify-content: center;
}
.home-logo-1 {
    display: flex;
    text-align: center;
    justify-content: center;
}
.home-logo-1 img {
    position: absolute;
    width: 50%;
    top: 3rem;
}

@keyframes Play {
    0%{
        transform: scale(1.2);
    }
    25%{
        transform: scale(1.1);
    }
    50%{
        transform: scale(1);
    }
    75%{
        transform: scale(1.1);
    }
    100%{
        transform: scale(1.2);
    }
    
}

.home-btns {
    display: flex;
    justify-content: space-around;
    padding-top: 34.5rem;

}
.home-winner-btn{
    display: flex;
    text-align: center;
    justify-content: center;
}
.home-winner-btn button {
    position: relative;
    top: 26rem;
    border: 3px solid #f7cd1f;
    padding: 3px 12px;
    border-radius: 10px;
    background-color: #986522;
    color: white;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 1px;
    cursor: pointer;
}
.home-audio-1{
    display: none;
}
@media only screen and (max-width: 450px){
    
    .home-cmplt-data {
        position: relative;
        bottom: 2rem;
    }
    } 
    @media only screen and (max-width: 380px){
    
        .home-cmplt-data {
            position: relative;
            bottom: 5rem;
        }
     } 
     .play-name p {
        position: relative;
        top: 23rem;
        font-size: 20px;
        font-weight: 600;
    }

    .play-name {
        display: flex;
        justify-content: center;
        text-align: center;
    }
     